<template lang="html">
    <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-account-tie" color="red">
          <v-row>
            <v-col cols="1" justify-center>
              <b><label>Filter:</label></b>
            </v-col>
            <v-col cols="11">
              <v-toolbar flat>
                <v-btn-toggle v-model="tab" color="primary accent-3" mandatory>
                  <v-btn medium value="all" color="secondary">Semua <br> ({{filterRefreshData.semua}})</v-btn>
                  <v-btn medium value="10" color="secondary">1.0 <br> ({{filterRefreshData.satu}})</v-btn>
                  <v-btn medium value="11" color="secondary">1.1 <br> ({{filterRefreshData.satusatu}})</v-btn>
                  <v-btn medium value="20" color="secondary">2.0 <br> ({{filterRefreshData.dua}})</v-btn>
                  <v-btn medium value="21" color="secondary">2.1 <br> ({{filterRefreshData.duasatu}})</v-btn>
                  <v-btn medium value="22" color="secondary">2.2 <br> ({{filterRefreshData.duadua}})</v-btn>
                  <v-btn medium value="30" color="secondary">3.0 <br> ({{filterRefreshData.progress}})</v-btn>
                  <v-btn medium value="40" color="secondary">4.0 <br> ({{filterRefreshData.tiga}})</v-btn>
                  <v-btn medium value="41" color="secondary">4.1 <br> ({{filterRefreshData.tigasatu}})</v-btn>
                  <v-btn medium value="42" color="secondary">4.2 <br> ({{filterRefreshData.tigadua}})</v-btn>
                  <v-btn medium value="50" color="secondary">5.0 <br> ({{filterRefreshData.empat}})</v-btn>
                  <v-btn medium value="60" color="secondary">6.0 <br> ({{filterRefreshData.enam}})</v-btn>
                </v-btn-toggle>
              <v-spacer></v-spacer>
              <v-btn color="green white--text" @click="tambahIKP()"><v-icon small left color="white">mdi-plus</v-icon> Tambah</v-btn>  
            </v-toolbar>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5">
            <div style="width:100%">
              <v-autocomplete v-model="selectSKPD" :items="skpdList" label="SKPD" item-text="v" item-value="k" hide-details></v-autocomplete>
            </div>
            </v-col>
            <v-col cols="5"></v-col>
            <v-col cols="2">
              <div>
                <!-- <v-btn small color="secondary" @click="importData()"><v-icon small color="white">mdi-file-excel</v-icon> &nbsp;Import</v-btn> -->
                <!-- <v-btn small color="green white--text" @click="exportExcel()"><v-icon small color="white">mdi-file-excel</v-icon> &nbsp;Export to Excel</v-btn> -->
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <b><label>Tanggal Instruksi:</label></b>
            </v-col>
            <v-col cols="2">
              <b><label>Tanggal Deadline:</label></b>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <v-menu
                v-model="fromDateMenuInstruksi"
                :close-on-content-click="false"
                :nudge-right="40"
                min-width="290px"
                transition="scale-transition"
                lazy offset-y full-width>
                <template v-slot:activator="{ on }">
                  <v-text-field v-show="!showItem" label="Tanggal Mulai" append-icon="mdi-calendar" dense readonly v-model="status_editor.tanggal_instruksi_awal" v-on="on"></v-text-field>
                </template>
                <v-date-picker
                locale="in-ID"
                v-model="status_editor.tanggal_instruksi_awal"
                no-title
                @input="fromDateMenuInstruksi = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-menu
                v-model="endDateMenuInstruksi"
                :close-on-content-click="false"
                :nudge-right="40"
                min-width="290px"
                transition="scale-transition"
                lazy offset-y full-width>
                <template v-slot:activator="{ on }">
                  <v-text-field v-show="!showItem" label="Tanggal Mulai" append-icon="mdi-calendar" dense readonly v-model="status_editor.tanggal_instruksi_akhir" v-on="on"></v-text-field>
                </template>
                <v-date-picker
                locale="in-ID"
                v-model="status_editor.tanggal_instruksi_akhir"
                no-title
                @input="endDateMenuInstruksi = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="2">
              <v-menu
                v-model="fromDateMenuDeadline"
                :close-on-content-click="false"
                :nudge-right="40"
                min-width="290px"
                transition="scale-transition"
                lazy offset-y full-width>
                <template v-slot:activator="{ on }">
                  <v-text-field v-show="!showItem" label="Tanggal Mulai" append-icon="mdi-calendar" dense readonly v-model="status_editor.tanggal_deadline_awal" v-on="on"></v-text-field>
                </template>
                <v-date-picker
                locale="in-ID"
                v-model="status_editor.tanggal_deadline_awal"
                no-title
                @input="fromDateMenuDeadline = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-menu
                v-model="endDateMenuDeadline"
                :close-on-content-click="false"
                :nudge-right="40"
                min-width="290px"
                transition="scale-transition"
                lazy offset-y full-width>
                <template v-slot:activator="{ on }">
                  <v-text-field v-show="!showItem" label="Tanggal Mulai" append-icon="mdi-calendar" dense readonly v-model="status_editor.tanggal_deadline_akhir" v-on="on"></v-text-field>
                </template>
                <v-date-picker
                locale="in-ID"
                v-model="status_editor.tanggal_deadline_akhir"
                no-title
                @input="endDateMenuDeadline = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-btn small color="green white--text" @click="exportExcel()"><v-icon small color="white">mdi-file-excel</v-icon> &nbsp;Export to Excel</v-btn>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headers"
            :items="itemLim"
            :loading="loading"
            :server-items-length="totalKeseluruhan"
            :page.sync="page"
            :items-per-page.sync="rowPerPage"
            :footer-props="{'items-per-page-options':[10,15,20]}"
          >
            <template v-slot:item.no="{item, index}">
              {{index+1}}
            </template>
            <template v-slot:item.instruksi="{item}">
              {{ item.instruksi }}
              <v-badge color="pink" top v-if="item.support" content="Support"></v-badge>
              <v-badge color="pink" top v-if="item.delegasi" content="Delegasi Atasan"></v-badge>
            </template>
            <template v-slot:item.leading="{item}">
              <br>
              {{item.kolok == null ? item.unit_kerja_nama : item.satuan_kerja_nama}}
              <br><br>
              <div v-if="item.support_sector_nama.length > 0">
                <b>Supporting Sector : </b>
                <template v-for="(support, index) in item.support_sector_nama">
                    <div v-bind:key="index" v-if="index <= 5">
                      - {{support}}
                    </div>
                    <div v-bind:key="index" v-if="index == 6">
                      - dll
                    </div>
                </template>
              </div>
              <br>
            </template>
            <template v-slot:item.status="{item}">
              {{ status_(item) }}
              <br><br><br>
              Progress{{item.support ? ' Supporting' : ''}} : {{item.lapor ? item.lapor.progress : item.progress}}% <br><br>
              <v-badge color="success" top v-if="item.progress == 100" content="Sudah Selesai"></v-badge>
              <v-badge color="red" top v-else content="Belum Selesai"></v-badge>
            </template>
            <template v-slot:item.tanggal_lapor="{ item }">
              {{item.lapor ? (item.lapor.progress == 0 ? 'Belum Dilaporkan' : item.lapor.tanggal_format) : 'Belum Dilaporkan'}}
            </template>
            <template v-slot:item.sisa_hari="{ item }">
              {{item.hari_ini > item.deadline ? (item.validasi == 'belum' ? 'Sudah Terlewat' : '-') : item.sisa_hari + ' Hari'}}
            </template>
            <template v-slot:item.aksi="{item}">
              <div class="d-flex flex-column justify-center">
                <v-btn width="70px" color="primary" x-small @click="detailIKP(item)"><v-icon small>mdi-magnify</v-icon>Lihat</v-btn>
                <v-btn width="180px" v-if="item.progress > 0" color="primary" x-small @click="lihatLapor(item)"><v-icon small>mdi-magnify</v-icon>Lihat Laporan Kepala Dinas</v-btn>
                <v-btn width="70px" v-if="item.progress == 0 && item.is_valid == null" color="yellow darken-1" x-small @click="edit(item)"><v-icon small>mdi-pencil</v-icon>Edit</v-btn>
                <v-btn width="70px" v-else disabled color="yellow darken-1" x-small @click="edit(item)"><v-icon small>mdi-pencil</v-icon>Edit</v-btn>
                <v-btn width="70px" v-if="item.progress != 100" color="red white--text" x-small @click="hapus(item)"><v-icon small>mdi-delete</v-icon>Hapus</v-btn>
              </div>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  
    <v-dialog v-model="dialogImportData" max-width="800px" >
      <v-card>
        <v-card-title>
          <span class="headline">Lihat Detail Laporan Realisasi IKP</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formDetailIKP">
            <v-row>
              <v-col cols="3" align="left">
                <span >Leading Sector* :</span>
              </v-col>
              <v-col cols="9" align="left">
                  &nbsp;&nbsp;{{detailItem.kolok == null ? detailItem.unit_kerja_nama : detailItem.satuan_kerja_nama}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" align="left">
                <span >Supporting Sector* :</span>
              </v-col>
              <v-col cols="9" align="left">
                  <template v-for="s in detailItem.support_sector_nama">
                    <span class="mx-2" v-bind:key="s">{{s}}</span>
                    <br v-bind:key="s">
                  </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" align="left">
                <span >Indikator* :</span>
              </v-col>
              <v-col cols="9" align="left">
                  &nbsp;&nbsp;{{detailItem.indikator+ ' ' +detailItem.satuan}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" align="left">
                <span >Tanggal Deadline* :</span>
              </v-col>
              <v-col cols="9" align="left">
                  &nbsp;&nbsp;{{detailItem.deadline}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" align="left">
                <span >Progress* :</span>
              </v-col>
              <v-col cols="9" align="left">
                  &nbsp;&nbsp;{{detailItem.progress}} %
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogImportData = false">Tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDetailIKP" max-width="800px" >
      <v-card>
        <v-card-title>
          <span class="headline">Lihat Detail Laporan Realisasi IKP</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formDetailIKP">
            <v-row>
              <v-col cols="3" align="left">
                <span >Leading Sector* :</span>
              </v-col>
              <v-col cols="9" align="left">
                  &nbsp;&nbsp;{{detailItem.kolok == null ? detailItem.unit_kerja_nama : detailItem.satuan_kerja_nama}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" align="left">
                <span >Supporting Sector* :</span>
              </v-col>
              <v-col cols="9" align="left">
                  <template v-for="s in detailItem.support_sector_nama">
                    <span class="mx-2" v-bind:key="s">{{s}}</span>
                    <br v-bind:key="s">
                  </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" align="left">
                <span >Indikator* :</span>
              </v-col>
              <v-col cols="9" align="left">
                  &nbsp;&nbsp;{{detailItem.indikator+ ' ' +detailItem.satuan}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" align="left">
                <span >Tanggal Deadline* :</span>
              </v-col>
              <v-col cols="9" align="left">
                  &nbsp;&nbsp;{{detailItem.deadline}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" align="left">
                <span >Durasi :</span>
              </v-col>
              <v-col cols="9" align="left">
                  &nbsp;&nbsp;{{detailItem.durasi}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" align="left">
                <span>Tanggal Instruksi* :</span>
              </v-col>
              <v-col cols="9" align="left">
                  &nbsp;&nbsp;{{detailItem.tanggal}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" align="left">
                <span >Data Pendukung :</span>
              </v-col>
              <v-col cols="9" align="left"> 
                <div v-if="detailItem.attachment">
                  <template v-for="(f,i) in parseFilenameAttachment(detailItem.attachment)">
                    <div v-bind:key="i">Data ke-{{i+1}} : <a class="font-weight-regular" :href="'https://mangbagja.bandung.go.id/i/'+ (typeof f !== 'undefined' ? f : f)" target="_blank">{{(typeof f.name !== 'undefined' ? f : f)}}</a></div>
                  </template>
                </div>
                <div v-else>-</div>
              </v-col>
            </v-row>
            <v-row v-if="detailItem.validasi != 'belum'">
              <v-col cols="3" align="left">
                <span>Keterangan Validasi :</span>
              </v-col>
              <v-col cols="9" align="left">
                  &nbsp;&nbsp;{{detailItem.keterangan}}
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="detailItem.is_valid == null" @click="terima(detailItem)" :loading="btnLoadingTerima" color="white--text green">Terima</v-btn>
          <v-btn v-if="detailItem.is_valid == null" @click="tolak(detailItem)" :loading="btnLoadingTolak" color="white--text red">Tolak</v-btn>
          <v-btn @click="dialogDetailIKP = false">Tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTambahIKP" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <h1>Tambah Instruksi Khusus Pimpinan</h1>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3" align="right">
              <span >Instruksi* :</span>
            </v-col>
            <v-col cols="9" align="left">
                <v-textarea outlined dense v-model="debounceText" height="60px"></v-textarea>
            </v-col>
          </v-row>
          <v-row v-if="editState">
            <v-col cols="3" align="right">
              <span >Termasuk Urusan Pemkot :</span>
            </v-col>
            <v-col cols="9" align="left">
              <v-radio-group v-model="formTambah.is_urusan_pemkot" column :rules="[rules.required]">
                <v-radio color="black" value="1">
                  <template v-slot:label>
                    <strong>Urusan Pemkot Bandung</strong>
                  </template>
                </v-radio>
                <v-radio color="black" value="0">
                  <template v-slot:label>
                    <strong>Bukan Urusan Pemkot Bandung</strong>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" align="right">
              <span >Leading Sector* :</span>
            </v-col>
            <v-col cols="9" align="left">
              <v-autocomplete v-model="formTambah.leading" :items="leadingSectorList_" dense label="Pilih SKPD" item-text="v" item-value="k" hide-details></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" align="right">
              <span >Supporting Sector* :</span>
            </v-col>
            <v-col cols="9" align="left">
              <v-btn-toggle v-model="formTambah.supporting" color="primary accent-3" @change="bindingSKPD" mandatory>
                <v-btn small value="skpd" color="secondary">Semua SKPD</v-btn>
                <v-btn small value="din" color="secondary">Semua Dinas</v-btn>
                <v-btn small value="kec" color="secondary">Semua Kecamatan</v-btn>
                <v-btn small value="clear" color="secondary">Clear</v-btn>
              </v-btn-toggle>
              <v-autocomplete v-model="formTambah.supporting_sector" :items="leadingSectorList" multiple chips label="Pilih SKPD" item-text="v" item-value="k" hide-details></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" align="right">
              <span>Indikator* :</span>
            </v-col>
            <v-col cols="3" align="left">
                <v-text-field outlined dense label="Nilai" v-model="formTambah.indikator"></v-text-field>
            </v-col>
            <v-col cols="3" align="left">
              <v-text-field outlined dense label="Satuan" v-model="formTambah.satuan"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" align="right">
              <span>Tanggal Deadline* :</span>
            </v-col>
            <v-col cols="9" align="left">
              <v-menu
                dense
                v-model="fromDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                min-width="290px"
                transition="scale-transition"
                lazy offset-y full-width>
                <template v-slot:activator="{ on }">
                  <v-text-field v-show="!showItem" label="Tanggal" append-icon="mdi-calendar" outlined dense readonly v-model="formTambah.deadline" v-on="on"></v-text-field>
                </template>
                <v-date-picker
                locale="in-ID"
                v-model="formTambah.deadline"
                no-title
                @input="fromDateMenu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" align="right">
              <span>Durasi</span>
            </v-col>
            <v-col cols="9" align="left">
              {{ durasi }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" align="right">
              <span>Tanggal Instruksi* :</span>
            </v-col>
            <v-col cols="9" align="left">
              <v-text-field outlined dense label="Tanggal Instruksi" v-model="tanggal" disabled></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" align="right">
              <span>Data Pendukung :</span>
            </v-col>
            <v-col cols="9" align="left">
              <file-input ref="fileInput" :helpText="'Data Pendukung (File XLS / DOC / PDF / JPG)'" :errorInput="fileInputErrorFlag" :uploadUrl="uploadUrl" @delFileUploaded="delFormFile" @fileUploaded="addFormFile"></file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="yellow darken-1" @click="dialogTambahIKP = false">Tutup</v-btn>
          <v-btn color="green darken-1 white--text" :loading="btnLoadingSimpan" @click="simpan()">Simpan</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDetailLaporanRealisasiIKP" max-width="800px" >
      <v-card>
        <v-card-title>
          <span class="headline">Lihat Detail Laporan Realisasi IKP</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formDetailIKP">
            <v-row>
              <v-col cols="3" align="left">
                <b><span >Leading Sector* :</span></b>
              </v-col>
              <v-col cols="9" align="left">
                  &nbsp;&nbsp;{{detailItem.kolok == null ? detailItem.unit_kerja_nama : detailItem.satuan_kerja_nama}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" align="left">
                <b><span >Supporting Sector* :</span></b>
              </v-col>
              <v-col cols="9" align="left">
                  <template v-for="s in detailItem.support_sector_nama">
                    <span class="mx-2" v-bind:key="s">{{s}}</span>
                    <br v-bind:key="s">
                  </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" align="left">
                <b><span >Indikator* :</span></b>
              </v-col>
              <v-col cols="9" align="left">
                  &nbsp;&nbsp;{{detailItem.indikator+ ' ' +detailItem.satuan}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" align="left">
                <b><span >Tanggal Deadline* :</span></b>
              </v-col>
              <v-col cols="9" align="left">
                  &nbsp;&nbsp;{{detailItem.deadline}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" align="left">
                <b><span>Tanggal Instruksi* :</span></b>
              </v-col>
              <v-col cols="9" align="left">
                  &nbsp;&nbsp;{{detailItem.tanggal}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" align="left">
                <b><span >Status :</span></b>
              </v-col>
              <v-col cols="9" align="left">
                  &nbsp;&nbsp;{{detailItem.lapor ? (detailItem.lapor.progress == 0 ? 'Belum Selesai' : (detailItem.lapor.progress == 100 ? 'Sudah Selesai' : 'Progress : '+detailItem.lapor.progress + '%')): 'Belum Selesai'}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" align="left">
                <b><span >Progress :</span></b>
              </v-col>
              <v-col cols="9" align="left">
                  &nbsp;&nbsp;{{detailItem.progress}}%
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" align="left">
                <b><span>Ringkasan Laporan :</span></b>
              </v-col>
              <v-col cols="9" align="left">
                  &nbsp;&nbsp;{{detailItem.lapor ? detailItem.lapor.keterangan : ''}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" align="left">
                <b><span >Data Pendukung Leading Sector : </span></b>
              </v-col>
              <v-col cols="9" align="left"> 
                <div v-if="detailItem.lapor">
                  <b><span>{{detailItem.satuan_kerja_nama}}</span></b>
                  <template v-for="(f,i) in parseFilenameEvidence(detailItem.lapor.file)">
                    <div v-bind:key="i">Data ke-{{i+1}} : <a class="font-weight-regular" :href="'https://mangbagja.bandung.go.id/i/'+ (typeof f !== 'undefined' ? f : f)" target="_blank">{{(typeof f.name !== 'undefined' ? f : f)}}</a></div>
                  </template>
                </div>
                <div v-else>-</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" align="left">
                <b><span >Data Pendukung Supporting Sectors :</span></b>
              </v-col>
              <v-col cols="9" align="left"> 
                <div v-if="detailItem.lapor_ss">
                  <template v-for="(d,index) in detailItem.lapor_ss">
                    <b v-bind:key="index"><span>{{d.satuan_kerja_nama}}</span></b>
                    <template v-for="(f,i) in parseFilenameEvidence(d.file)">
                      <div v-bind:key="i">Data ke-{{i+1}} : <a class="font-weight-regular" :href="'https://mangbagja.bandung.go.id/i/'+ (typeof f !== 'undefined' ? f : f)" target="_blank">{{(typeof f.name !== 'undefined' ? f : f)}}</a></div>
                    </template>
                    <br v-bind:key="index">
                  </template>
                </div>
                <div v-else>-</div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogDetailLaporanRealisasiIKP = false">Tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  
    </v-container>
  </template>
  
  <script>
  import _ from 'lodash'
  import store from '@/store'
  import IKPService from '@/services/IKPService'
  import {defaultRules} from '@/utils/lib'
  import FileInput from '@/components/common/FileInputIKP'
  import { mapState } from 'vuex'
  import _g from '../../global'
  import axios from 'axios'
  
  export default {
    components:{
      FileInput
    },
  
    data(){
      return{
        forceUpdate:false,
        loading:false,
        tab: null,
  
        headers:[
          {text:'No', value:'no', sortable:false, class:'header-index-profile'},
          {text:'Instruksi', value:'instruksi', sortable:false, class:'header-index-profile'},
          {text:'Leading Sector', value:'leading', sortable:false, class:'header-index-profile'},
          {text:'Status', value:'status', sortable:false, class:'header-index-profile'},
          {text:'Tanggal Instruksi', value:'tanggal_format', sortable:false, class:'header-index-profile'},
          {text:'Deadline', value:'deadline_format', sortable:false, class:'header-index-profile'},
          {text:'Tanggal Lapor', value:'tanggal_lapor', sortable:false, class:'header-index-profile'},
          {text:'Sisa Hari', value:'sisa_hari', sortable:false, class:'header-index-profile'},
          {text:'Aksi', value:'aksi', sortable:false}
        ],
        crudSettings:{
          title:'',
          detailTitle:'Detail',
          deleteTitleKey:'peg_nama',
          enableHeader:true,
          enableDetail:false,
          enableCreate:false,
        },
        itemLim:[],
        detailItem: {},
        dialogDetailIKP: false,
        formTambah: {},
        loadingBtn:{
          laporkan:false,
        },
        rules:{
          ...defaultRules
        },
        fileInputErrorFlag:false,
        showItem:false,
        fromDateMenu:false,
        endDateMenu: false,
        fromDateMenuInstruksi:false,
        endDateMenuInstruksi: false,
        fromDateMenuDeadline:false,
        endDateMenuDeadline: false,
        status_editor:{},
        selectSKPD: '',
        skpdList:[],
        leadingSectorList:[],
        leadingSectorList_:[],
        totalKeseluruhan:0,
        rowPerPage:10,
        page:1,
        dialogTambahIKP:false,
        btnLoadingSimpan:false,
        btnLoadingTerima:false,
        btnLoadingTolak:false,
        skpd:[],
        kecamatan:[],
        dinas:[],
        tanggal:'',
        durasi:'',
        dialogImportData: false,
        filterRefreshData:{},
        editState: false,
        dialogDetailLaporanRealisasiIKP:false
      }
    },
  
    watch:{
      page(val){
        this.tableValue.page = val
        this.updateTable(this.tableValue)
      },
      'formTambah.deadline': function(val){
        let tanggal_d = val.substring(8,10); let bulan_d = val.substring(5,7); let tahun_d = val.substring(0,4)
        let tanggal_i = this.tanggal.substring(8,10); let bulan_i = this.tanggal.substring(5,7); let tahun_i = this.tanggal.substring(0,4)
        const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
        const firstDate = new Date(tahun_d, bulan_d, tanggal_d)
        const secondDate = new Date(tahun_i, bulan_i, tanggal_i)
        // this.durasi = Math.round(Math.abs((firstDate - secondDate) / oneDay))+ ' Hari'
        this.durasi = Math.round((firstDate - secondDate) / oneDay)+ ' Hari'
      }
    },

    mounted(){
      this.$watch(vm => [vm.rowPerPage, vm.search, vm.selectSKPD, vm.selectBulan, vm.tab], () => {
        this.page = 1
        this.updateTable({
          itemsPerPage:this.rowPerPage,
          kolok:this.selectSKPD,
          search:this.search,
          page:this.page,
          sorts: this.tab,
          tanggalInstruksiAwal: this.status_editor.tanggal_instruksi_awal,
          tanggalInstruksiAkhir: this.status_editor.tanggal_instruksi_akhir,
          tanggalDeadlineAwal: this.status_editor.tanggal_deadline_awal,
          tanggalDeadlineAkhir: this.status_editor.tanggal_deadline_akhir
        })
      })
    },
  
    created(){
      let val = {itemsPerPage:10, page: 1,}
      this.getListSKPD()
      this.getLeadingSectorSKPD()
      this.updateTable(val)
      this.refreshData()
    },
  
    computed:{
      debounceText: {
        get() { return this.formTambah.instruksi != '' ? this.formTambah.instruksi : this.text; },
        set: _.debounce(function(newValue) {
          this.formTambah.instruksi = newValue
        }, 100)
      },
      uploadUrl(){
        return process.env.VUE_APP_API_URL + '/api/mb-upload/ikp-admin/'
        // return process.env.VUE_APP_API_URL + '/api/ms/2/upload-evidence-skp-tahunan'
      },
      ...mapState({
        username: state=> state.user.current.username,
        user: state=> state.user.dataPegawai,
        current: state=> state.user.current
      }),
    },
  
    methods:{
      status_(item){
        return item.is_read != null ? (item.role_id != 'asisten' && item.role_id != 'sekda' && item.role_id != 'admin_kpp' ? '6.0. Laporan Realisasi Sudah Dibaca Oleh Walikota' : '5.0. Laporan Realisasi Diterima') : 
                  (item.is_read == null && item.is_valid != null && item.is_valid == 1 && item.lapor && item.lapor.progress < 100 && item.validasi == 'sudah' ? '5.1. Laporan Realisasi Diterima Dan Tidak Mencapai Target' :
                  (item.is_read == null && item.is_valid != null && item.is_valid == 1 && item.lapor && item.lapor.progress == 100 && item.validasi == 'sudah' ? '5.0. Laporan Realisasi Diterima' : 
                  (item.is_read == null && item.is_valid != null && item.is_valid == 1 && item.lapor && item.validasi == 'ulang' ? '4.2. Laporan Realisasi ditolak dan diinstruksikan ulang' :
                  (item.is_read == null && item.is_valid != null && item.is_valid == 1 && item.lapor && item.lapor.progress == 100 && item.validasi == 'tolak' ? '4.1. Laporan Realisasi ditolak dan Instruksi Selesai' : 
                  (item.is_read == null && item.is_valid != null && item.is_valid == 1 && item.lapor && item.lapor.progress == 100 && item.validasi == 'belum' ? '4.0. SKPD sudah melaporkan realisasi dan sedang menunggu fase validasi asisten' : 
                  (item.is_read == null && item.is_valid != null && item.is_valid == 1 && item.lapor && item.lapor.progress > 0 && item.lapor.progress < 100 && item.validasi == 'belum' ? '3.0. SKPD sudah melaporkan realisasi (On Progress)' : 
                  (item.is_read == null && item.is_valid != null && item.is_valid == 1 && item.usul && item.usul && !item.usul.expired ? '2.2. Usulan Perubahan Di Tolak' : 
                  (item.is_read == null && item.is_valid != null && item.is_valid == 1 && item.lapor && item.lapor.progress == 0 && item.hari_ini > item.deadline ? '2.2. SKPD tidak melaporkan hasil realisasi dan deadline sudah terlewati' : 
                  (item.is_read == null && item.is_valid != null && item.is_valid == 1 && item.usul && item.usul.expired ? '2.1. SKPD mengusulkan perubahan instruksi' : 
                  (item.is_read == null && item.is_valid != null && item.is_valid == 1 && item.lapor && item.lapor.progress == 0 && item.validasi == 'belum' ? '2.0 Instruksi Sedang dalam Proses Pengerjaan oleh SKPD' : 
                  (item.is_read == null && item.is_valid != null && item.is_valid != 1 ? '1.1. Instruksi Bukan Kewenangan Kota Bandung' : 
                  (item.is_read == null && item.is_valid == null ? '1.0. Instruksi sedang dalam proses verifikasi Asisten' : ''))))))))))))
      },

      tambahIKP(){
        this.editState = false
        this.dialogTambahIKP = true
      },

      updateTable(val){
        this.tableValue = val ? val: this.tableValue
        var params = {}
        params.sorts = this.tableValue.sorts
        params.tanggal_instruksi_awal = this.tableValue.tanggal_instruksi_awal
        params.tanggal_instruksi_akhir = this.tableValue.tanggal_instruksi_akhir
        params.tanggal_deadline_awal = this.tableValue.tanggal_deadline_awal
        params.tanggal_deadline_akhir = this.tableValue.tanggal_deadline_akhir
        params.kolok = this.tableValue.kolok
        IKPService.getAllIKP({page:this.tableValue.page,
          perpage:this.tableValue.itemsPerPage,
          search:this.tableValue.search,
          params:JSON.stringify(params),
          order:'instruksi',
          order_direction:'',
          is_admin:true})
        .then((response)=>{
          let res = response.data
          this.totalKeseluruhan = res.total_keseluruhan
          this.itemLim = res.data
          this.eselon = res.eselon
          this.jft = res.jft
          this.skpd = res.skpd
          this.kecamatan = res.kecamatan
          this.dinas = res.dinas
          this.tanggal = res.tanggal
          console.log(this.itemLim)
          if (res.status == 200){
            store.commit('snackbar/setSnack',{message: (res.message ? res.message : 'Get Data Berhasil'), color:'success'})
          }
        }).finally(()=>{
          this.loadingTahunan = false
        })
      },
  
      detailIKP(item){
        this.dialogDetailIKP = true
        this.detailItem = item
        let tanggal_d = item.deadline.substring(8,10); let bulan_d = item.deadline.substring(5,7); let tahun_d = item.deadline.substring(0,4)
        let tanggal_i = item.tanggal.substring(8,10); let bulan_i = item.tanggal.substring(5,7); let tahun_i = item.tanggal.substring(0,4)
        const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
        const firstDate = new Date(tahun_d, bulan_d, tanggal_d)
        const secondDate = new Date(tahun_i, bulan_i, tanggal_i)
        this.detailItem.durasi = Math.round((firstDate - secondDate) / oneDay)+ ' Hari'
        this.detailItem.enableTerimaTolak = true
      },
  
      delFormFile(file){
        let regex = /\d+_([\w\s-]+)-[\w\d]+\.(\w+)/
        let filename = file.file.name
        this.formTambah.attachment = this.formTambah.attachment.filter((val)=>{
          const match = val.match(regex)
          let curr_filename = match[1]+'.'+match[2]
          if (match){
            return filename != curr_filename
          }
          return false
        })
      },
  
      addFormFile(file){
        this.formTambah.attachment.push(file)
        console.log(this.formTambah.attachment)
      },
  
      parseFilenameEvidence(filename){
        var arr = []
        try{
          if(filename != undefined){
            let parsedFilename = JSON.parse(filename)
            if(parsedFilename.length > 0){
              for (let i = 0; i < parsedFilename.length; i++) {
                const element = parsedFilename[i]
                if(element.name != undefined){
                  let parsed = JSON.parse(element.name)
                  arr.push(parsed)
                }else{
                  arr.push(element)
                }
              }
            }
            return arr
          }else{
            return 
          }
        } catch(e){
          return filename
        }
      },
      parseFilenameAttachment(filename){
          return filename
      },

      getListSKPD(){
        let current = store.state.user.current
        axios.post(_g.getBaseURLAPIERK(current.year, "v1/get-data-skpd2")).then(response => {
          let data = []
          _.each(response.data.data, function(value, key, obj){
            data.push({k:key, v: value})
          })
          this.skpdList = data
        }).catch(err => {
          var error = err.message
          store.commit('snackbar/setSnack',{message: error, color:'error'})
        })
      },

      getLeadingSectorSKPD(){
        let current = store.state.user.current
        var dataLeadingSectorList_ = []
        dataLeadingSectorList_.push({k:'all-skpd', v:'Semua SKPD'})
        dataLeadingSectorList_.push({k:'all-dinas', v:'Semua Dinas'})
        dataLeadingSectorList_.push({k:'all-kecamatan', v:'Semua Kecamatan'})
        var data = []
        axios.post(_g.getBaseURLAPIERK(current.year, "v1/lim/get-skpd")).then(response => {
          _.each(response.data.data, function(value, key, obj){
            data.push({k:key, v: value})
            dataLeadingSectorList_.push({k:key, v: value})
          })
          this.leadingSectorList = data
          this.leadingSectorList_ = dataLeadingSectorList_
        }).catch(err => {
          var error = err.message
          store.commit('snackbar/setSnack',{message: error, color:'error'})
        })
      },

      bindingSKPD(val){
        var sup = val ? val : this.formTambah.supporting
        if(sup == 'skpd'){
          this.formTambah.supporting_sector = this.skpd
        }
        if(sup == 'din'){
          this.formTambah.supporting_sector = this.dinas
        }
        if(sup == 'kec'){
          this.formTambah.supporting_sector = this.kecamatan
        }
        if(sup == 'clear'){
          this.formTambah.supporting_sector = []
        }
      },

      simpan(){
        this.btnLoadingSimpan = true
        let current = store.state.user.current
        var data = {}
        var url = ''
        if(this.editState){
          data = {
            "api": 1,
            "id": this.formTambah.id,
            "instruksi": this.formTambah.instruksi,
            "deadline": this.formTambah.deadline,
            "kolok": this.formTambah.leading,
            "kojab": this.formTambah.kojab,
            "tanggal": this.tanggal,
            "indikator": this.formTambah.indikator,
            "satuan": this.formTambah.satuan,
            "support_sector": this.formTambah.supporting_sector,
            "is_urusan_pemkot": this.formTambah.is_urusan_pemkot,
            "attachment": this.formTambah.attachment ? this.formTambah.attachment : [],
          }
          url = _g.getBaseURLAPIERK(current.year, "v1/lim/edit")
        }else{
          data = {
            "api": 1,
            "instruksi": this.formTambah.instruksi,
            "deadline": this.formTambah.deadline,
            "kolok": this.formTambah.leading,
            // "kojab": this.formTambah.kojab,
            "tanggal": this.tanggal,
            "indikator": this.formTambah.indikator,
            "satuan": this.formTambah.satuan,
            "support_sector": this.formTambah.supporting_sector,
            "attachment": this.formTambah.attachment ? this.formTambah.attachment : [],
          }
          url = _g.getBaseURLAPIERK(current.year, "v1/lim/store")
        }
        axios.post(url, {...data}).then(response => {
          let res = response.data
          if (res.success){
            store.commit('snackbar/setSnack',{message: res.message, color:'success'})
          } else {
            store.commit('snackbar/setSnack',{message: res.message, color:'error'})
          }
          this.dialogTambahIKP = false
          this.btnLoadingSimpan = false
        }).catch(err => {
          this.btnLoadingSimpan = false
          var error = err.message
          store.commit('snackbar/setSnack',{message: error, color:'error'})
        })
      },

      exportExcel(){
        var current = this.$store.getters["user/current"]
        var tanggalfrom = this.status_editor.tanggal_instruksi_awal
        var tanggalto = this.status_editor.tanggal_instruksi_akhir
        var deadlinefrom = this.status_editor.tanggal_deadline_awal
        var deadlineto = this.status_editor.tanggal_deadline_akhir
        var year = current.year
        var base = process.env.VUE_APP_API_URL
        if(base === 'https://mangbagja-demo.rev.web.id/'){
          base = 'https://erk-demo.rev.web.id/'
        }else{
          base = 'https://kinerja.bandung.go.id/'
        }
        if(tanggalfrom && tanggalto && deadlinefrom && deadlineto){
          var urls = base+year+"/api/v1/lim/export?api=1&tanggalfrom="+tanggalfrom+"&tanggalto="+tanggalto+"&deadlinefrom="+deadlinefrom+"&deadlineto="+deadlineto
          window.open(urls,'_blank');
        }else{
          store.commit('snackbar/setSnack',{message: 'Pilih Dahulu Tanggal Instruksi dan Tanggal Deadline', color:'warning'})
        }
      },

      importData(){
        this.dialogImportData = true
      },

      refreshData(){
        let current = store.state.user.current
        axios.post(_g.getBaseURLAPIERK(current.year, "v1/lim/refresh-filter"),{api:1}).then(response => {
          let res = response.data
          if (res.success){
            let data = response.data.data
            store.commit('snackbar/setSnack',{message: res.message, color:'success'})
            this.filterRefreshData.semua = data.semua
            this.filterRefreshData.satu = data.satu
            this.filterRefreshData.satusatu = data.satusatu
            this.filterRefreshData.dua = data.dua
            this.filterRefreshData.duasatu = data.duasatu
            this.filterRefreshData.duadua = data.duadua
            this.filterRefreshData.tiga = data.tiga
            this.filterRefreshData.tigasatu = data.tigasatu
            this.filterRefreshData.tigadua = data.tigadua
            this.filterRefreshData.empat = data.empat
            this.filterRefreshData.nol = data.nol
            this.filterRefreshData.progress = data.progress
            this.filterRefreshData.enam = data.enam
          }else {
            store.commit('snackbar/setSnack',{message: res.message, color:'error'})
          }
        }).catch(err => {
          var error = err.message
          store.commit('snackbar/setSnack',{message: error, color:'error'})
        })
      },

      edit(item){
        this.formTambah = item
        this.editState = true
        this.formTambah.is_urusan_pemkot = '1'
        if(item.kolok == null){
          this.formTambah.leading = 'unit.'+item.unit_kerja_id
        }else{
          this.formTambah.leading = item.kode_skpd
        }
        this.dialogTambahIKP = true
      },

      terima(item){
        this.btnLoadingTerima = true
        let current = store.state.user.current
        axios.post(_g.getBaseURLAPIERK(current.year, "v1/lim/terimaLim"),{api:1,id:item.id}).then(response => {
          let res = response.data
          if (res.success){
            this.dialogDetailIKP = false
            store.commit('snackbar/setSnack',{message: res.message, color:'success'})
            this.updateTable({itemsPerPage:10, page: 1,})
          }else {
            store.commit('snackbar/setSnack',{message: res.message, color:'error'})
          }
          this.btnLoadingTerima = false
        }).catch(err => {
          this.btnLoadingTerima = false
          var error = err.message
          store.commit('snackbar/setSnack',{message: error, color:'error'})
        })
      },

      tolak(item){
        this.btnLoadingTolak = true
        let current = store.state.user.current
        axios.post(_g.getBaseURLAPIERK(current.year, "v1/lim/tolakLim"),{api:1,id:item.id}).then(response => {
          let res = response.data
          if (res.success){
            this.dialogDetailIKP = false
            store.commit('snackbar/setSnack',{message: res.message, color:'success'})
            this.updateTable({itemsPerPage:10, page: 1,})
          }else {
            store.commit('snackbar/setSnack',{message: res.message, color:'error'})
          }
          this.btnLoadingTolak = false
        }).catch(err => {
          this.btnLoadingTolak = false
          var error = err.message
          store.commit('snackbar/setSnack',{message: error, color:'error'})
        })
      },

      hapus(item) {
        if(confirm("Apakah Anda yakin akan menghapus?")){
          let current = store.state.user.current
          axios.post(_g.getBaseURLAPIERK(current.year, "v1/lim/hapus"),{api:1,id:item.id}).then(response => {
            let res = response.data
            if (res.success){
              this.dialogDetailIKP = false
              store.commit('snackbar/setSnack',{message: res.message, color:'success'})
              this.updateTable({itemsPerPage:10, page: 1,})
            }else {
              store.commit('snackbar/setSnack',{message: res.message, color:'error'})
            }
          }).catch(err => {
            var error = err.message
            store.commit('snackbar/setSnack',{message: error, color:'error'})
          })
        }
      },

      lihatLapor(item){
        this.detailItem = {}
        this.dialogDetailLaporanRealisasiIKP = true
        this.detailItem = item
      }
  
    },
  }
  </script>
  
  <style lang="css" scoped>
  </style>
  