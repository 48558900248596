import request from '@/utils/request'
import store from '@/store'
import _g from '../../src/global'

const IKPService = {
  getAllIKP,
  getListLaporSupportIKP,
  laporIKP,
  validasiSupportingIKP
}

function getAllIKP({page,
  perpage,
  search,
  order,
  order_direction,
  is_admin,
  params}){
  var current = store.state.user.data
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH") ){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/ikp/get-all',
    method: 'post',
    data: {
      nip: is_admin ? current.username+'-'+is_admin : current.username,
      page: page,
      perpage: perpage ? perpage : 20,
      search: search ? search : '',
      order: order ? order : 'instruksi',
      order_direction: order_direction ? order_direction : 'asc',
      params:params
    }
  })
}

function getListLaporSupportIKP({page,
  perpage,
  search,
  order,
  order_direction,
  params,
  id_lim}){
  var current = store.state.user.data
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH") ){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/ikp/get-list-lapor-support',
    method: 'post',
    data: {
      nip: current.username,
      page: page,
      perpage: perpage ? perpage : 20,
      search: search ? search : '',
      order: order ? order : '',
      order_direction: order_direction ? order_direction : 'asc',
      params: params,
      id_lim: id_lim
    }
  })
}

function laporIKP(data) {
  var current = store.state.user.data
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH") ){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/ikp/lapor',
    method: 'post',
    data: {
      nip : current.username,
      id : data.id,
      progress : data.progress,
      tanggal : data.tanggal,
      support : data.support,
      id_lapor : data.lapor ? data.lapor.id : null,
      keterangan : data.keterangan,
      attachment : data.attachment
    }
  })
}

function validasiSupportingIKP(data) {
  var current = store.state.user.data
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH") ){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/ikp/validasi-support',
    method: 'post',
    data: {
      nip : current.username,
      id : data.id,
      id_lim : data.id_lim,
      kolok : data.kolok,
      respon : data.respon
    }
  })
}

export default IKPService